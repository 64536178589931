import { render, staticRenderFns } from "./ListInventory.vue?vue&type=template&id=3dc4d607&scoped=true&"
import script from "./ListInventory.vue?vue&type=script&lang=js&"
export * from "./ListInventory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dc4d607",
  null
  
)

export default component.exports