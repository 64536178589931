<template>
  <div>
    <div class="form-row">
      <ListPlanningVehicle
        label="ยานพาหนะ"
        class="col-6"
        v-model="selectedVehicle"
        :docType="docType"
        :templateType="templateType"
        select="id">

      </ListPlanningVehicle>
      <sgv-input-month
        label="เดือน"
        v-model="selectedMonth"
        class="col-6">
      </sgv-input-month>
    </div>

    <sgv-table
      :rKey="rKey"
      :items="datelist"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">
      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.date">
            {{item.date | date}}
            <small class="text-success">
              <br>
              {{item.total}} กล่อง
            </small>

          </td>
          <td v-if="hidden.docs">
            <div v-for="doc in item.docs" :key="doc.id">
              {{doc.box}} กล่อง
              |
              {{$date.format(doc.deliveredAt).time}}
              |
              <router-link
                class="text-decoration-none"
                :class="{disabled: !doc.isLink}"
                :to="toDetail(doc)">
                <span :class="getStatus(doc)">
                  {{doc.code}}
                </span>
              </router-link>
              | {{doc.name}}
              <div v-if="doc.remark">
                <small class="text-primary">{{doc.remark}}</small>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PLANNING } from './graph'
import ListPlanningVehicle from './ListPlanningVehicle'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      headers: [
        {text: 'วันที่', value: 'date', sort: false, filter: false},
        {text: 'เอกสาร', value: 'docs', sort: false, filter: false}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['date', 'docs'],
        column: null,
        search: null,
        toolbar: null,
      },
      rKey: `Doc${this.$form.capitalize(this.docType)}ListPlanning`,
      rFields: ['options', 'selectedMonth', 'selectedVehicle'],
      selectedMonth: '',
      selectedVehicle: null,
      items: [],
      docs: []
    }
  },
  computed: {
    datelist () {
      if (!this.selectedMonth) return []
      const d = new Date(this.selectedMonth + '-01')
      const arr = this.$date.serial(this.$date.format(d).startOfMonth, this.$date.format(d).endOfMonth)
      return arr.map(v => {
        const found = this.items.filter(x => {
          const deliveredDate = this.$date.format(x.deliveredAt).date
          return deliveredDate === v
        }).sort((a, b) => new Date(a.code) - new Date(b.code))

        return {
          date: v,
          docs: found,
          total: found.reduce((t,v) => t += v.box, 0)
        }
      })
    },
  },
  apollo: {
    docs: {
      query () {
        return LIST_PLANNING(this.templateType)
      },
      variables () {
        this.setRetaining()
        return {
          docType: this.docType,
          month: this.selectedMonth + '-01',
          vehicleId: this.selectedVehicle
        }
      },
      skip () {
        return !this.selectedMonth
      },
      fetchPolicy: 'network-only',
      debounce: 150,
      result (res) {
        this.items = res.data.docs.map(v => ({
          ...v,
          isLink: this.$auth.hasRole(`doc:${v.type}:read`)
        }))
      }
    }
  },
  methods: {
    toDetail (item) {
      return {
        name: `Doc${this.$form.capitalize(item.type)}Detail`,
        params: {docId: item.id}
      }
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    }
  },
  components: {
    ListPlanningVehicle
  }
}
</script>
