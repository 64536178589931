<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="reportFilter.startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.reportFilter.startDate.$dirty && $v.reportFilter.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="reportFilter.endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.reportFilter.endDate.$dirty && $v.reportFilter.endDate.$error}
        ]">
      </sgv-input-date>
    </div>

    <button class="btn btn-warning" @click="fetchData">
      ค้นหา
    </button>

    <div class="form-row mt-3">
      <sgv-input-month
        class="col-6"
        v-model="syncFilter.month"
        label="เดือน"
        isNull
        :validations="[
          {text: 'required!', value: $v.syncFilter.month.$dirty && $v.syncFilter.month.$error}
        ]">
      </sgv-input-month>
    </div>

    <button class="btn btn-info" @click="syncReport">
      Sync
    </button>

    <sgv-table
      class="mt-3"
      ref="docList"
      :rKey="rKey"
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      headerless>

      <template slot-scope="{item, idx}">
        <tr>
          <td>
            <router-link
              class="text-decoration-none"
              :to="toContact(item)">
              {{idx+1}}. {{item.code}} ({{item.name}})
            </router-link>
          </td>
          <td>
            <div
              v-for="doc in item.docs"
              :key="doc.id">
              <router-link
                class="text-decoration-none"
                :to="toDoc(doc)">
                {{doc.code}}
                <small>
                  <div class="text-primary pre-line" v-if="doc.remark">
                    {{item.remark}}
                  </div>
                </small>
              </router-link>
            </div>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import {
  LIST_REPORT_NEW_CONTACT,
  SYNC_REPORT_NEW_CONTACT
} from './graph'
import { required } from 'vuelidate/lib/validators'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListReportNewContact`,
      reportFilter: {
        startDate: '',
        endDate: '',
      },
      syncFilter: {
        month: '',
      },
      rFields: ['reportFilter', 'syncFilter'],
      items: [],
      headers: [
        {text: 'ผู้ติดต่อ', value: 'contact'},
        {text: 'เอกสาร', value: 'docs'}
      ],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['contact', 'docs'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  validations: {
    reportFilter: {
      startDate: {required},
      endDate: {required},
    },
    syncFilter: {
      month: {required}
    }
  },
  methods: {
    fetchData () {
      this.$v.reportFilter.$touch()
      if (this.$v.reportFilter.$invalid) return

      this.setRetaining()
      this.items = []
      this.$apollo.query({
        query: LIST_REPORT_NEW_CONTACT(this.templateType),
        variables: {
          docType: this.docType,
          startDate: this.$date.format(this.reportFilter.startDate).utc,
          endDate: this.$date.format(this.reportFilter.endDate, 0, 1).utc,
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    syncReport () {
      this.$v.syncFilter.$touch()
      if (this.$v.syncFilter.$invalid) return

      this.setRetaining()

      this.$apollo.mutate({
        mutation: SYNC_REPORT_NEW_CONTACT(this.templateType),
        variables: {
          docType: this.docType,
          month: this.syncFilter.month
        },
        fetchPolicy: 'no-cache'
      })
      .then(() => {
        this.$toasted.global.success('อัพเดทสำเร็จ')
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
    },
    toContact (contact) {
      if (this.$auth.hasRole(`contact:${contact.type}:read`)) {
        return {
          name: `Contact${this.$form.capitalize(contact.type)}Detail`,
          params: {contactId: contact.id},
          query: {redirect: this.$route.name}
        }
      }

      return ''
    },
    toDoc (doc) {
      if (this.$auth.hasRole(`doc:${doc.type}:read`)) {
        return {
          name: `Doc${this.$form.capitalize(doc.type)}Detail`,
          params: {docId: doc.id},
          query: {redirect: this.$route.name}
        }
      }

      return ''
    },
    getStatus (item) {
      if (item.approvedAt && !item.closedAt) return ['text-warning']
      else if (item.closedAt) return ['text-success']
    },
  }
}
</script>

<style lang="css" scoped>
</style>
