<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">
          {{title}}
          <router-link
            class="text-decoration-none text-secondary"
            v-if="$auth.hasRole(`doc:${docType}:config`)"
            :to="toDocConfig()">
            <small><fa icon="cog"></fa></small>
          </router-link>

          <UserManual
            class="ml-2"
            :templateType="templateType"
            :docType="docType">
          </UserManual>
        </h3>
      </div>

      <div class="dashhead-toolbar">
        <div class="dashhead-toolbar-item">
          <Category
            :docType="docType"
            :templateType="templateType"
            :options="categories"
            v-model="selectedCategory"
            @docStatus="docStatus = $event"
            @categoryId="categoryId = $event">
          </Category>
        </div>
      </div>
    </div>

    <hr class="my-3">

    <ListReport
      v-if="docStatus === 'report'"
      :docType="docType"
      :templateType="templateType"
      :group="group">
    </ListReport>

    <ListInventory
      v-else-if="docStatus === 'inventoryValidation'"
      :docType="docType"
      :templateType="templateType"
      :group="group">
    </ListInventory>

    <ListPlanning
      v-else-if="docStatus === 'planning'"
      :docType="docType"
      :templateType="templateType"
      :group="group">
    </ListPlanning>

    <ListCategory
      v-else
      :docType="docType"
      :templateType="templateType"
      :categoryId="categoryId"
      :group="group"
      :docStatus="docStatus"
      :invoiceType="invoiceType">
    </ListCategory>
  </div>
</template>

<script>
import ListCategory from './ListCategory.vue'
import ListInventory from './ListInventory.vue'
import ListPlanning from './ListPlanning.vue'
import ListReport from './ListReport'
import retainMixin from '@/mixins/retain-mixin'
import Category from '@/views/doc_core/components/Category'
import UserManual from '@/views/doc_core/components/UserManual'


export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    invoiceType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      docConfigView : `Doc${this.$form.capitalize(this.docType)}ConfigList`,
      rKey: `Doc${this.$form.capitalize(this.docType)}List`,
      categories: [
        {text: 'ทั้งหมด', docStatus: null, categoryId: 0},
        {text: 'สร้าง', docStatus: 'createdAt', categoryId: 0},
        {text: 'ยังไม่ได้จัด', docStatus: 'notPrepared', categoryId: 0},
        {text: 'ยังไม่ครบ', docStatus: 'partialPrepared', categoryId: 0},
        {text: 'พร้อมส่ง', docStatus: 'fullPrepared', categoryId: 0},
        {text: 'กำลังจัดส่ง', docStatus: 'onDeliver', categoryId: 0},
        {text: 'จัดส่งแล้ว', docStatus: 'delivered', categoryId: 0},
        {text: 'อนุมัติ', docStatus: 'approvedAt', categoryId: 0},
        {text: 'เสร็จ', docStatus: 'closedAt', categoryId: 0},
        {text: 'รายงาน', docStatus: 'report', categoryId: 0},
        {text: 'เช็คสต๊อก', docStatus: 'inventoryValidation', categoryId: 0},
        {text: 'แผนการจัดส่ง', docStatus: 'planning', categoryId: 0},
      ],
      selectedCategory: 'ทั้งหมด',
      docStatus: null,
      categoryId: 0,
      rFields: ['selectedCategory']
    }
  },
  methods: {
    toDocConfig () {
      return {name: this.docConfigView}
    },
  },
  watch: {
    selectedCategory: 'setRetaining'
  },
  components: {
    ListCategory,
    ListInventory,
    ListPlanning,
    ListReport,
    Category,
    UserManual,
  }
}
</script>

<style lang="css" scoped>
</style>
