var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"เริ่ม","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.reportFilter.startDate.$dirty && _vm.$v.reportFilter.startDate.$error}
      ]},model:{value:(_vm.reportFilter.startDate),callback:function ($$v) {_vm.$set(_vm.reportFilter, "startDate", $$v)},expression:"reportFilter.startDate"}}),_c('sgv-input-date',{staticClass:"col-6",attrs:{"label":"ถึง","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.reportFilter.endDate.$dirty && _vm.$v.reportFilter.endDate.$error}
      ]},model:{value:(_vm.reportFilter.endDate),callback:function ($$v) {_vm.$set(_vm.reportFilter, "endDate", $$v)},expression:"reportFilter.endDate"}})],1),_c('button',{staticClass:"btn btn-warning",on:{"click":_vm.fetchData}},[_vm._v(" ค้นหา ")]),_c('div',{staticClass:"form-row mt-3"},[_c('sgv-input-month',{staticClass:"col-6",attrs:{"label":"เดือน","isNull":"","validations":[
        {text: 'required!', value: _vm.$v.syncFilter.month.$dirty && _vm.$v.syncFilter.month.$error}
      ]},model:{value:(_vm.syncFilter.month),callback:function ($$v) {_vm.$set(_vm.syncFilter, "month", $$v)},expression:"syncFilter.month"}})],1),_c('button',{staticClass:"btn btn-info",on:{"click":_vm.syncReport}},[_vm._v(" Sync ")]),_c('sgv-table',{ref:"docList",staticClass:"mt-3",attrs:{"rKey":_vm.rKey,"items":_vm.items,"headers":_vm.headers,"filter":_vm.filter,"options":_vm.options,"headerless":""},on:{"update:filter":function($event){_vm.filter=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var idx = ref.idx;
return [_c('tr',[_c('td',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toContact(item)}},[_vm._v(" "+_vm._s(idx+1)+". "+_vm._s(item.code)+" ("+_vm._s(item.name)+") ")])],1),_c('td',_vm._l((item.docs),function(doc){return _c('div',{key:doc.id},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":_vm.toDoc(doc)}},[_vm._v(" "+_vm._s(doc.code)+" "),_c('small',[(doc.remark)?_c('div',{staticClass:"text-primary pre-line"},[_vm._v(" "+_vm._s(item.remark)+" ")]):_vm._e()])])],1)}),0)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }