import gql from 'graphql-tag'


export const LIST_REPORT_DELIVER_DELAY = (templateType) => gql`query LIST_REPORT_DELIVER_DELAY ($docType: String!, $startDate: Date!, $endDate: Date!) {
  docs: listDoc${templateType}ReportDeliverDelay (docType: $docType, startDate: $startDate, endDate: $endDate) {
    id type code name remark closedAt deliveredAt
  }
}`

export const LIST_REPORT_NEW_CONTACT = (templateType) => gql`query LIST_REPORT_NEW_CONTACT ($docType: String!, $startDate: DateTime!, $endDate: DateTime!) {
  items: listDoc${templateType}ReportNewContact (docType: $docType, startDate: $startDate, endDate: $endDate)
}`

export const SYNC_REPORT_NEW_CONTACT = (templateType) => gql`mutation SYNC_REPORT_NEW_CONTACT ($docType: String!, $month: String!) {
  result: syncDoc${templateType}ReportNewContact (docType: $docType, month: $month)
}`

export const LIST_INVENTORY_VALIDATION = (templateType) => gql`query LIST_INVENTORY_VALIDATION ($docType: String!) {
  inventories: listDoc${templateType}InventoryValidation (docType: $docType) {
    id type code name
    unitId unit {id name}
    inStock remaining
    soTotalStock soActualStock
    docs {
      id type code name remark
      createdAt approvedAt closedAt
      total actual
    }
  }
}`
