import { render, staticRenderFns } from "./ListReportNewContact.vue?vue&type=template&id=b277a3ce&scoped=true&"
import script from "./ListReportNewContact.vue?vue&type=script&lang=js&"
export * from "./ListReportNewContact.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b277a3ce",
  null
  
)

export default component.exports