import gql from 'graphql-tag'


export const LIST_ADDRESS_DELIVERY_LOCATION = (templateType) => gql`query LIST_ADDRESS_DELIVERY_LOCATION ($docType: String!, $docId: Int!) {
  addresses: listDoc${templateType}ContactAddressDeliveryLocation (docType: $docType, docId: $docId) {
    id type name contactId
    attendance address
    telephone mobile 
    fax email lineApp
    isDefault
  }
}`
