import gql from 'graphql-tag'


export const LIST_PLANNING = (templateType) => gql`query LIST_PLANNING ($docType: String!, $vehicleId: Int!, $month: String!) {
  docs: listDoc${templateType}Planning (docType: $docType, vehicleId: $vehicleId, month: $month) {
    id type code name box remark
    deliveredAt createdAt approvedAt closedAt
  }
}`

export const LIST_PLANNING_VEHICLE = (templateType) => gql`query LIST_PLANNING_VEHICLE ($docType: String!) {
  items: listDoc${templateType}PlanningVehicle (docType: $docType) {
    id type code name
  }
}`
