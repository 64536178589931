<template>
  <sgv-table
    :rKey="rKey"
    :items="inventories"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr class="pointer" @click="toggleRow(item.id)">
        <td v-if="hidden.id">
          {{item.code}} ({{item.name}}) {{item.unit.name}}
        </td>
        <td v-if="hidden.description">
          ใช้ได้: <span class="text-success">{{item.inStock}}</span> |
          เกิน(ขาด):
          <span :class="{'text-success': item.remaining >= 0, 'text-danger': item.remaining < 0}">
            {{item.remaining}}
          </span>
          <br>
          จัดไปแล้ว: <span class="text-primary">{{Math.abs(item.soActualStock)}}</span> |
          ขายทั้งหมด: <span class="text-primary">{{Math.abs(item.soTotalStock)}}</span>
        </td>
      </tr>
      <tr v-if="selected === item.id">
        <td colspan="2">
          <ul class="mb-0">
            <li v-for="doc in item.docs" :key="doc.id">
              <router-link
                class="text-decoration-none"
                :class="{'text-warning': doc.approvedAt}"
                :to="toDetail(doc.id)">
                {{doc.code}}
              </router-link>
              ({{doc.name}})
              [ <span class="text-warning">{{doc.actual}}</span> / <span class="text-success">{{doc.total}}</span> ]
              <small v-if="doc.remark" class="text-primary">
                <br>
                {{doc.remark}}
              </small>
            </li>
          </ul>
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_INVENTORY_VALIDATION } from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      docs: [],
      detailView: `Doc${this.$form.capitalize(this.docType)}Detail`,
      rKey: `Doc${this.$form.capitalize(this.docType)}ListInventory`,
      headers: [
        {text: 'สินค้า', value: 'id'},
        {text: 'รายละเอียด', value: 'description'}
      ],
      filter: {
        limit: 0,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'description'],
        column: null,
        search: null,
        toolbar: null,
      },
      selected: null
    }
  },
  apollo: {
    inventories: {
      query () {
        return LIST_INVENTORY_VALIDATION(this.templateType)
      },
      variables () {
        return {docType: this.docType}
      },
      fetchPolicy: 'no-cache'
    }
  },
  methods: {
    toggleRow (id) {
      if (this.selected === id) this.selected = null
      else this.selected = id
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {docId: id}
      }
    },
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
