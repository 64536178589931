import gql from 'graphql-tag'


export const LIST_ORDER_HISTORY = (templateType) => gql`query LIST_ORDER_HISTORY ($docType: String!, $contactId: Int!, $q: FilterInput) {
  orders: listDoc${templateType}OrderHistory (docType: $docType, contactId: $contactId, q: $q) {
    id type name parentId
    docId doc {id type code name remark}
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
  }
}`
