import gql from 'graphql-tag'


export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {
    id type name docId parentId
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
    stocks {id}
    ledgers {
      id amount stockId
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    children {
      id type name parentId
      docId doc {id type code name closedAt}
      unitId unit {id name}
      inventoryId inventory {id type code name}
      qty price totalPrice
      stocks {
        id qty parentId
        inventoryId inventory {
          id type code name
          unitId unit {id name}
        }
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
      ledgers {
        id amount stockId
        accountId account {id code name}
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
    }
  }
}`

export const CREATE_ORDER = (templateType) => gql`mutation CREATE_ORDER ($docType: String!, $type: String!, $docId: Int!, $input: Doc${templateType}OrderInput!) {
  createOrder: createDoc${templateType}Order (docType: $docType, type: $type, docId: $docId, input: $input) {id}
}`

export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const CHECKOUT_ORDER = (templateType) => gql`mutation CHECKOUT_ORDER ($docType: String!, $docId: Int!, $stockId: Int!, $orderId: Int) {
  checkoutOrder: checkoutDoc${templateType}Order (docType: $docType, docId: $docId, stockId: $stockId, orderId: $orderId) {id}
}`

export const UNCHECK_ORDER = (templateType) => gql`mutation UNCHECK_ORDER ($docType: String!, $orderId: Int!) {
  uncheckOrder: uncheckDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {
    id type name docId parentId
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
    stocks {id}
    ledgers {
      id amount stockId
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    children {
      id type name parentId
      docId doc {id type code name closedAt}
      unitId unit {id name}
      inventoryId inventory {id type code name}
      qty price totalPrice
      stocks {
        id qty parentId
        inventoryId inventory {
          id type code name
          unitId unit {id name}
        }
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
      ledgers {
        id amount stockId
        accountId account {id code name}
        createdAt createdBy createdUser {id name}
        approvedAt approvedBy approvedUser {id name}
      }
    }
  }
}`

export const WATCH_ORDER_CHECKOUTED = (templateType) => gql`subscription WATCH_ORDER_CHECKOUTED ($docType: String!, $docId: Int!) {
  orderCheckouted: watchDoc${templateType}OrderCheckouted (docType: $docType, docId: $docId) {
    id type name parentId
    docId doc {id type code name closedAt}
    unitId unit {id name}
    inventoryId inventory {id type code name}
    qty price totalPrice
    stocks {
      id qty parentId
      inventoryId inventory {
        id type code name
        unitId unit {id name}
      }
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
    ledgers {
      id amount stockId
      accountId account {id code name}
      createdAt createdBy createdUser {id name}
      approvedAt approvedBy approvedUser {id name}
    }
  }
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id parentId}
}`

export const CHECKOUT_ORDER_EXPENSE = (templateType) => gql`mutation CHECKOUT_ORDER_EXPENSE ($docType: String!, $docId: Int!, $orderId: Int) {
  checkoutOrder: checkoutDoc${templateType}OrderExpense (docType: $docType, docId: $docId, orderId: $orderId) {id}
}`

export const CHECKOUT_ORDER_INVENTORY = (templateType) => gql`mutation CHECKOUT_ORDER_INVENTORY ($docType: String!, $orderId: Int!, $qty: Float!) {
  checkoutOrder: checkoutDoc${templateType}OrderInventoryId (docType: $docType, orderId: $orderId, qty: $qty) {id}
}`

export const WATCH_ORDER_REFETCH = (templateType) => gql`subscription WATCH_ORDER_REFETCH ($docType: String!, $docId: Int!) {
  orderRefetch: watchDoc${templateType}OrderRefetch (docType: $docType, docId: $docId) {id type}
}`


export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $type: String!, $contactId: Int!, $q: FilterInput, $qty: Float, $docConfigId: Int) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, type: $type, contactId: $contactId, q: $q, qty: $qty, docConfigId: $docConfigId) {
    id type name isDefault salesPrice
    inventoryId inventory {id type code name}
    unitId unit {id name}
    children {
      id type name converter
    }
  }
}`

export const LIST_ORDER_EXPENSE = (templateType) => gql`query LIST_ORDER_EXPENSE ($docType: String!, $contactId: Int!) {
  expenses: listDoc${templateType}OrderExpense (docType: $docType, contactId: $contactId) {
    id type name isDefault salesPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_DISCOUNT_PRICE = (templateType) => gql`query LIST_ORDER_DISCOUNT_PRICE ($docType: String!, $contactId: Int!) {
  discountPrices: listDoc${templateType}OrderDiscountPrice (docType: $docType, contactId: $contactId) {
    id type name isDefault discountPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_VAT_PRICE = (templateType) => gql`query LIST_ORDER_VAT_PRICE ($docType: String!) {
  vatPrices: listDoc${templateType}OrderVatPrice (docType: $docType) {
    id type name isDefault vatPrice
    unitId unit {id name}
  }
}`

export const LIST_ORDER_NET_PRICE = (templateType) => gql`query LIST_ORDER_NET_PRICE ($docType: String!) {
  netPrices: listDoc${templateType}OrderNetPrice (docType: $docType) {
    id type name isDefault
    unitId unit {id name}
  }
}`
