import gql from 'graphql-tag'


export const LIST_STOCK_BALANCE = (templateType) => gql`query LIST_STOCK_BALANCE ($docType: String!, $inventoryIds: [Int!]!) {
  stocks: listDoc${templateType}StockBalance (docType: $docType, inventoryIds: $inventoryIds) {
    inventoryId
    inventory {
      id type code name
      unit {id name}
    }
    current future
  }
}`
